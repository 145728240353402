import cn from "classnames"
import CallToAction from "components/CallToAction"
import { getStoryblokLink } from "utilities/getStoryblokLink"

export default function CTABanner({
  blok = {
    heading: "We're here to help you. Speak with us today.",
    cta_link: {
      linktype: "story",
      fieldtype: "multilink",
      cached_url: "learn-more/contact-us",
    },
    cta_label: "Contact Us",
    style: "teal",
  },
}) {
  return (
    <section className="cta-banner-section relative z-50" data-pagefind-ignore>
      <div
        className={cn("relative overflow-hidden text-white", {
          "leaves-decor bg-teal": blok.style === "teal",
          "brush-decor bg-licorice": blok.style === "licorice",
        })}
      >
        <div className="mx-auto flex flex-col items-center justify-center gap-4 px-10 py-14 md:flex-row md:gap-8">
          <h3 className="text-center lg:text-left">{blok.heading}</h3>
          <CallToAction
            href={getStoryblokLink(blok.cta_link)}
            style={blok.style === "licorice" ? "primary" : "secondary"}
            className="relative z-10"
          >
            {blok.cta_label}
          </CallToAction>
        </div>
      </div>
    </section>
  )
}
